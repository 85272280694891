<template>
    <div class="login_popup" @childFn="parentFn">
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="374px"
          :before-close="handleClose"
          :show-close="false">
          <i class="el-icon-close closei" @click="closei()"></i>
          <div class="miniMain">
            <!-- 登录页面 -->
            <div class="main_item" v-show="l_Num==1">
              <div class="nav_tab">
                <span class="nav_tab_item" :class="istrue==1?'active_nav':''" @click="isBox(1)">
                  账号登录
                </span>
                <span class="nav_tab_item" :class="istrue==2?'active_nav':''" @click="isBox(2)">
                  手机号登录
                </span>
              </div>
              <!-- 账号密码登录 -->
              <div class="login_form form">
                <el-form ref="ruleForm" :model="ruleForm">
                  <el-form-item>
                    <el-input v-model.number="ruleForm.account" placeholder="手机号" @focus="focus" @keyup.enter.native="enterLogin($event)"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-input v-model="ruleForm.password" type="password" placeholder="密码" @focus="focus" v-show="istrue==1" @keyup.enter.native="enterLogin($event)"></el-input>
                    <el-input v-model="ruleForm.captcha"  placeholder="短信验证码" @focus="focus" v-show="istrue==2" @keyup.enter.native="enterLogin($event)">
                      <template slot="append" >
                        <span class="append_span" :class="disabled==true?'form_disabled':''" :disabled='disabled' @click="sendcode()">{{Vercode}}</span>
                      </template>
                    </el-input>
                  </el-form-item>
                  <div class="keepOnline_wrap keepOnline_account">
                    <p class="form_errorMsg login_erroMsg">
                      {{msg}}
                    </p>
                  </div>
                </el-form>
                <!-- 立即登录 -->
                <div class="form_button" @click="loginBox()">
                  <span>立即登录</span>
                  <!-- <span v-show="this.istrue==2">立即登录/注册</span> -->
                </div>
              </div>
              <!-- 忘记密码和注册账号 -->
              <div class="login_bottom">
                <span class="js_forgot_mini createLink" @click="regist()">注册新账号</span>
                <span class="js_forgot_mini forgot" @click="forget()" v-show="this.istrue==1">忘记密码</span>
              </div>
            </div>
            <!-- 忘记密码 和 注册账号 -->
            <div class="main_item" v-show="l_Num!=1">
              <div class="nav">
                  <span class="nav_back_svg" @click="back()">
                    <i class="el-icon-arrow-left"></i>
                  </span>
                  <span class="nav_title" v-show="l_Num==2">忘记密码</span>
                  <span class="nav_title" v-show="l_Num==3">注册账号</span>
              </div>
              <div class="login_form form">
                <!-- 忘记密码 -->
                  <el-form ref="Form" :model="Form" v-show="l_Num==2">
                    <el-form-item>
                        <el-input v-model.number="Form.account" placeholder="手机号" @focus="focus"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="Form.captcha" placeholder="短信验证码" @focus="focus">
                        <template slot="append" >
                          <span class="append_span" :class="disabled==true?'form_disabled':''" :disabled='disabled' @click="sendregist()">{{Vercode}}</span>
                        </template>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="Form.password" type="password" :placeholder="l_Num==2?'新密码':'密码'" @focus="focus" show-password></el-input>
                    </el-form-item>
                    <div class="keepOnline_wrap keepOnline_account">
                      <p class="form_errorMsg login_erroMsg">
                        {{msg}}
                      </p>
                      <p class="form_errorMsg defaultError" v-show="msg==''">
                        密码为8-16位大小写字母、数字或符号3种组合
                      </p>
                    </div>
                  </el-form>
                  <!-- 注册账号 -->
                  <el-form ref="Forms" :model="Forms" v-show="l_Num==3">
                    <el-form-item>
                        <el-input v-model.number="Forms.account" placeholder="手机号" @focus="focus"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="Forms.captcha" placeholder="短信验证码" @focus="focus">
                        <template slot="append" >
                          <span class="append_span" :class="disabled==true?'form_disabled':''" :disabled='disabled' @click="sendregist()">{{Vercode}}</span>
                        </template>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="Forms.passwd" type="password" placeholder="密码" @focus="focus" show-password></el-input>
                    </el-form-item>
                    <div class="keepOnline_wrap keepOnline_account">
                      <p class="form_errorMsg login_erroMsg">
                        {{msg}}
                      </p>
                      <p class="form_errorMsg defaultError" v-show="msg==''">
                        密码为8-16位字母、数字或符号2种组合
                      </p>
                    </div>
                  </el-form>
                  <!-- 重置密码 -->
                  <div class="form_button" @click="resetBox()">
                    <span v-show="l_Num==2">重置密码</span>
                    <span v-show="l_Num==3">立即注册</span>
                  </div>
              </div>
            </div>
          </div>
      </el-dialog>
    </div>
</template>
<script>
export default {
  props: ['title'],
  data() {
    return {
      l_Num:1,
      dialogVisible:false,
      istrue:1,
      ruleForm:{
        account:'',
        password:'',
        captcha:'',
      },
      Form:{
        account:'',
        password:'',
        captcha:'',
      },
      Forms:{
        account:'',
        passwd:'',
        captcha:'',
      },
      msg:'',
      disabled:false,
      Vercode:'发送验证码',
    }
  },
  methods: {
    //登录方式切换
    isBox(val){
      this.istrue=val
      this.msg = ''
    },
    // 获取焦点
    focus(){
      this.msg = ''
    },
    //发送验证码
    sendcode(){
      if(this.disabled==false){
        if(!this.ruleForm.account){
          this.msg='请输入手机号码'
        }else if(!/^1[3456789]\d{9}$/.test(this.ruleForm.account)){
          this.msg='请输入正确的手机号码'
        }else{
            // 登录获取验证码
            this.axios.smslogin(
              this.ruleForm
            ).then(res=>{
              if(res.data.code==0){
                this.time = 60
                this.timer()
              }else{
                this.$message.warning(res.data.message)
              }
            }).catch(err=>{
              console.log(err)
            })
        }
      }
    },
    //注册、忘记密码
    sendregist(){
      if(this.disabled==false){
        if(this.l_Num==2){
          if(!this.Form.account){
              this.msg='请输入手机号码'
          }else if(!/^1[3456789]\d{9}$/.test(this.Form.account)){
              this.msg='请输入正确的手机号码'
          }else{
                // 忘记密码获取验证码
                this.axios.smsresetpwd(
                  this.Form
                ).then(res=>{
                  if(res.data.code==0){
                    this.time = 60
                    this.timer()
                  }else{
                    this.msg = res.data.message
                  }
                }).catch(err=>{
                  console.log(err)
                })
          }
        }else if(this.l_Num==3){
            if(!this.Forms.account){
                this.msg='请输入手机号码'
            }else if(!/^1[3456789]\d{9}$/.test(this.Forms.account)){
                this.msg='请输入正确的手机号码'
            }else{
                // 注册账号获取验证码
                this.axios.smsregist(
                  this.Forms
                ).then(res=>{
                  if(res.data.code==0){
                    this.time = 60
                    this.timer()
                  }else{
                    this.msg = res.data.message
                  }
                }).catch(err=>{
                  console.log(err)
                })
            }
        }
          
      }
    },
    timer(){
        if(this.time>0){
          this.disabled = true
          this.time--
          this.Vercode = "重发("+this.time+")"
          setTimeout(this.timer,1000)
        }else{
          this.time = 0
          this.Vercode = "发送验证码"
          this.disabled = false
        }
    },
    // 登录
    loginBox(){
      if(this.istrue==1){
        if(!this.ruleForm.account){
          this.msg='请输入手机号码'
        }else if(!/^1[3456789]\d{9}$/.test(this.ruleForm.account)){
          this.msg='请输入正确的手机号码'
        }else if(!this.ruleForm.password){
          this.msg = '请输入密码'
        }else{
          this.axios.login({
            type:'passwd',
            account:this.ruleForm.account,
            password:this.ruleForm.password
          }).then(res=>{
            if(res.data.code==0){
              this.dialogVisible = false
              this.$store.dispatch("token",res.data.data.token)
              this.$store.dispatch("account",res.data.data.account)
              this.$store.dispatch("avatar",res.data.data.avatar)
              this.$store.dispatch("userName",res.data.data.name)
              this.$store.dispatch("vip",res.data.data.vip)
              this.$store.dispatch("vipName",res.data.data.vipName)
              this.$store.dispatch("vipDate",res.data.data.vipDate)
              location.reload();
            }else{
              this.msg = res.data.message
            }
          }).catch(err=>{
            console.log(err)
          })
        }
      }else if(this.istrue==2){
        if(!this.ruleForm.account){
          this.msg='请输入手机号码'
        }else if(!/^1[3456789]\d{9}$/.test(this.ruleForm.account)){
          this.msg = '请输入正确的手机号码'
        }else if(!this.ruleForm.captcha){
          this.msg = '请输入验证码'
        }else{
          this.axios.login({
            type:'captcha',
            account:this.ruleForm.account,
            password:this.ruleForm.captcha
          }).then(res=>{
            if(res.data.code==0){
              this.dialogVisible = false
              this.$store.dispatch("token",res.data.data.token)
              this.$store.dispatch("account",res.data.data.account)
              this.$store.dispatch("avatar",res.data.data.avatar)
              this.$store.dispatch("userName",res.data.data.name)
              this.$store.dispatch("vip",res.data.data.vip)
              this.$store.dispatch("vipName",res.data.data.vipName)
              this.$store.dispatch("vipDate",res.data.data.vipDate)
              location.reload();
            }else{
              this.msg = res.data.message
            }
          }).catch(err=>{
            console.log(err)
          })
        }
      }
    },
    //重置密码
    resetBox(){
      if(this.l_Num==2){
        if(!this.Form.account){
          this.msg='请输入手机号码'
        }else if(!/^1[3456789]\d{9}$/.test(this.Form.account)){
          this.msg = '请输入正确的手机号码'
        }else if(!this.Form.captcha){
          this.msg = '请输入验证码'
        }else if(!this.Form.password){
          this.msg = '请输入新密码'
        }else if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,}$/.test(this.Form.password)){
          this.msg = "密码为8-16位字母、数字或符号2种组合"
        }else{
          // 成功
          this.axios.czresetpwd(
            this.Form
          ).then(res=>{
            if(res.data.code==0){
              this.l_Num=1
            }else{
              this.msg = res.data.message
            }
          }).catch(err=>{
            console.log(err)
          })
        }
      }else if(this.l_Num==3){
        if(!this.Forms.account){
          this.msg='请输入手机号码'
        }else if(!/^1[3456789]\d{9}$/.test(this.Forms.account)){
          this.msg = '请输入正确的手机号码'
        }else if(!this.Forms.captcha){
          this.msg = '请输入验证码'
        }else if(!this.Forms.passwd){
          this.msg = '请输入密码'
        }else if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,}$/.test(this.Forms.passwd)){
          this.msg = "密码为8-16位字母、数字或符号2种组合"
        }else{
          // 成功
          this.axios.regist(
            this.Forms
          ).then(res=>{
            if(res.data.code==0){
              console.log(res.data.data)
              this.dialogVisible=false
              this.$store.dispatch("token",res.data.data.token)
              this.$store.dispatch("account",res.data.data.account)
              this.$store.dispatch("avatar",res.data.data.avatar)
              this.$store.dispatch("userName",res.data.data.name)
              this.$store.dispatch("vip",res.data.data.vip)
              this.$store.dispatch("vipName",res.data.data.vipName)
              this.$store.dispatch("vipDate",res.data.data.vipDate)
              location.reload();
            }else{
              this.msg = res.data.message
            }
          }).catch(err=>{
            console.log(err)
          })
        }
      }
        
    },
    back(){
      this.l_Num = 1
      this.msg = ''
    },
    //注册账号
    regist(){
      this.l_Num = 3
      this.msg = ''
    },
    //忘记密码
    forget(){
      this.l_Num = 2
      this.msg = ''
    },
    handleClose(done){
      console.log(done)
    },
    parentFn(payload) {
        this.dialogVisible = payload;
    },
    closei(){
      this.dialogVisible = false
      if(this.$route.name == 'Downloadlatest' || this.$route.name == 'Mycollection' || this.$route.name == 'Messagecenter' || this.$route.name == 'Conversioncode' || this.$route.name == 'Perstion' || this.$route.name == 'Chapassword'){
        this.$router.push({name:'indexPage'})
      }
      this.$emit('childFn', false);
    },
    enterLogin(e){
      if(e.which == 13 || e.charCode == 13 || e.keyCode == 13){
        this.loginBox()
      }
    },
    
  },
  watch: {
    title:function(val){
      this.dialogVisible = val
    }
  },
  mounted() {
  },
}
</script>
<style lang="scss">
@import "./login.scss"
</style>