import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:localStorage.getItem('token') ? localStorage.getItem('token') : '',             //token标识
    openid:localStorage.getItem('openid') ? localStorage.getItem('openid') : '',             //token标识
    account:localStorage.getItem('account') ? localStorage.getItem('account') : '',       //手机号
    avatar:localStorage.getItem('avatar') ? localStorage.getItem('avatar') : '',          //头像
    vip:localStorage.getItem('vip') ? localStorage.getItem('vip') : false,                   //是否VIP
    vipName:localStorage.getItem('vipName') ? localStorage.getItem('vipName') : '',       //vip名称
    vipDate:localStorage.getItem('vipDate') ? localStorage.getItem('vipDate') : '',       //Vip截至日期
    uploadimage:localStorage.getItem('uploadimage') ? localStorage.getItem('uploadimage') : '',       //Vip截至日期
    userName:localStorage.getItem('userName') ? localStorage.getItem('userName') : '',       //Vip截至日期
  },
  mutations: {
    token(state,value){
      state.token = value
      localStorage.setItem('token',value);
    },
    openid(state,value){
      state.openid = value
      localStorage.setItem('openid',value);
    },
    account(state,value){
      state.account = value
      localStorage.setItem('account',value);
    },
    avatar(state,value){
      state.avatar = value
      localStorage.setItem('avatar',value);
    },
    vip(state,value){
      state.vip = value
      localStorage.setItem('vip',value);
    },
    vipName(state,value){
      state.vipName = value
      localStorage.setItem('vipName',value);
    },
    vipDate(state,value){
      state.vipDate = value
      localStorage.setItem('vipDate',value);
    },
    uploadimage(state,value){
      state.uploadimage = value
      localStorage.setItem('uploadimage',value);
    },
    userName(state,value){
      state.userName = value
      localStorage.setItem('userName',value);
    },
  },
  actions: {
    token(context,value){
      context.commit('token',value)
    },
    openid(context,value){
      context.commit('openid',value)
    },
    account(context,value){
      context.commit('account',value)
    },
    avatar(context,value){
      context.commit('avatar',value)
    },
    vip(context,value){
      context.commit('vip',value)
    },
    vipName(context,value){
      context.commit('vipName',value)
    },
    vipDate(context,value){
      context.commit('vipDate',value)
    },
    uploadimage(context,value){
      context.commit('uploadimage',value)
    },
    userName(context,value){
      context.commit('userName',value)
    },
  },
  getters: {
    
  },
});
